import React from "react"
import SEO from "../components/Seo"
import ArchiveList from "../components/ArchiveList"
import { useStaticQuery, graphql } from "gatsby"

const ArchivePage = () => {
  const data = useStaticQuery(graphql`
    query {
      currentBuildDate {
        currentDate
      }
      site {
        siteMetadata {
          instagram
          title
        }
      }
      allMarkdownRemark(
        filter: { frontmatter: { published: { eq: true } } }
        sort: { fields: [frontmatter___title], order: ASC }
      ) {
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              client
              date(formatString: "dddd DD MMMM YYYY", locale: "fr-FR")
              type
              videos {
                url
                poster {
                  name
                  publicURL
                  colors {
                    vibrant
                  }
                  childImageSharp {
                    fluid(quality: 90, maxHeight: 1200) {
                      srcSetWebp
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              published
              poster {
                name
                publicURL
                colors {
                  vibrant
                }
                childImageSharp {
                  fluid(quality: 90, maxHeight: 1200) {
                    srcSetWebp
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const projects = data.allMarkdownRemark.edges

  return (
    <>
      <SEO title="Archive" />
      <section className="container max-w-screen-2xl lg:p-10 md:p-8 p-5 text-brand-white tracking-tight">
        <ArchiveList projects={projects} />
      </section>
      <footer className="container max-w-screen-xl font-sans p-5 sm:p-8 text-xs lg:text-sm text-brand-white text-center">
        <p>
          &copy;{data.currentBuildDate.currentDate}{" "}
          {data.site.siteMetadata.title} | Legal |{" "}
          <a
            className="transition duration-300 ease-in-out hover:opacity-50"
            href={data.site.siteMetadata.instagram}
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>
        </p>
      </footer>
    </>
  )
}

export default ArchivePage
