import React from "react"
import { Link } from "gatsby"
// import Img from "gatsby-image"
import { sortByClientTitle } from "../utils/sortByClientTitle"

const ArchiveList = ({ projects }) => {
  let sortedProjects = sortByClientTitle(projects)

  return (
    <ul>
      {sortedProjects.map((project, id) => (
        <li key={id} className="archiveList">
          <Link
            to={`/projects/${project.node.fields.slug}?cat=archive&pro=${id}`}
            className="flex text-xl sm:text-4xl leading-snug sm:leading-supertight font-semibold transition duration-300 ease-in-out hover:text-brand-gray no-underline"
          >
            {
              // console.log(
              //   project.node.frontmatter.poster,
              //   project.node.frontmatter.poster
              // )
            }
            {project.node.frontmatter.client ? (
              <p>
                {project.node.frontmatter.client}{" "}
                {project.node.frontmatter.title}
              </p>
            ) : (
              <p>{project.node.frontmatter.title}</p>
            )}
            <div className={"archiveListImageWrapper"}>
              <div
                className={"archiveListImage"}
                style={{
                  backgroundImage:
                    project.node.frontmatter.type === "video"
                      ? project.node.frontmatter.videos[0].poster
                        ? `url("${project.node.frontmatter.videos[0].poster.childImageSharp.fluid.src}")`
                        : null
                      : project.node.frontmatter.poster
                      ? `url("${project.node.frontmatter.poster.childImageSharp.fluid.src}")`
                      : null,
                }}
              ></div>
            </div>
          </Link>
        </li>
      ))}
    </ul>
  )
}

export default ArchiveList
