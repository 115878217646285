export const sortByClientTitle = projects =>
  projects.sort((a, b) => {
    let titleA = a.node.frontmatter.title
    let titleB = b.node.frontmatter.title
    if (a.node.frontmatter.client) {
      titleA = `${a.node.frontmatter.client} ${a.node.frontmatter.title}`
    }
    if (b.node.frontmatter.client) {
      titleB = `${b.node.frontmatter.client} ${b.node.frontmatter.title}`
    }
    let sortit = titleA.localeCompare(titleB)
    return sortit
  })
